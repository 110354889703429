import React from 'react';
import {
  TFunction,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentSummary.st.css';
import Text, { TextType } from '../../Text/Text';
import { PaymentSummaryDataHook } from './dataHooks';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
  ServicePaymentDetails,
} from '../../../../types/types';
import { Divider } from 'wix-ui-tpa/Divider';
import { TagName } from '../../../../utils/tagName.const';
import { PaymentDetails } from '../PaymentDetails/PaymentDetails';
import { getContent } from '../../../../utils/content/content';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { CouponDetails } from '@wix/ambassador-checkout-server/types';
import { Spinner } from 'wix-ui-tpa/Spinner';
import { PriceUtils } from '@wix/bookings-uou-mappers';
import { PriceItem } from './PriceItem/PriceItem';
import { FormStatus } from '../../../../types/form-state';

export type PaymentSummaryProps = {
  servicePayment: ServicePaymentDetails;
  selectedPaymentOption: PaymentOption;
  dateRegionalSettingsLocale: string;
  numberOfParticipants: number;
  status: FormStatus;
  showPricePerParticipant: boolean;
  appliedCoupon?: CouponDetails;
};

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  servicePayment,
  selectedPaymentOption,
  dateRegionalSettingsLocale,
  numberOfParticipants,
  appliedCoupon,
  showPricePerParticipant,
  status,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const {
    price,
    priceText,
    currency,
    minCharge: deposit,
    totalPrice,
  } = servicePayment;

  const isPricingPlanSelected =
    !selectedPaymentOption.disabled &&
    ![
      ReservedPaymentOptionIds.SingleSession,
      ReservedPaymentOptionIds.BuyAPricingPlan,
    ].includes(selectedPaymentOption.id as ReservedPaymentOptionIds);

  const showSingleSessionSummary = (): boolean => {
    const serviceHasPrice = price > 0 || !!priceText;
    return (
      serviceHasPrice &&
      selectedPaymentOption.id === ReservedPaymentOptionIds.SingleSession
    );
  };

  const shouldShowPaymentSummary = () => {
    return showSingleSessionSummary() || isPricingPlanSelected;
  };

  const sectionTitle = getContent({
    settings,
    settingsParam: settingsParams.summaryPaymentSectionTitle,
  });

  const couponDiscount = Number(appliedCoupon?.couponDiscount) || 0;
  const isProcessingPaymentDetails =
    status === FormStatus.PROCESSING_PAYMENT_DETAILS;

  const priceBreakDownItems = getPriceBreakDownItems({
    locale: dateRegionalSettingsLocale,
    numberOfParticipants,
    showPricePerParticipant,
    couponDiscount,
    price,
    currency,
    t,
  });

  const totalPriceItems = getTotalPriceItems({
    locale: dateRegionalSettingsLocale,
    price,
    totalPrice,
    deposit,
    t,
    currency,
  });

  return shouldShowPaymentSummary() ? (
    <div
      className={st(classes.root, { isProcessingPaymentDetails })}
      data-hook={PaymentSummaryDataHook.Payment_Summary}
    >
      <Divider className={classes.divider} />
      <Text
        tagName={TagName.H3}
        type={TextType.Primary}
        className={classes.title}
        data-hook={PaymentSummaryDataHook.Title}
      >
        {sectionTitle}
      </Text>
      {showSingleSessionSummary() ? (
        <div className={classes.singleSession}>
          {isProcessingPaymentDetails ? (
            <div className={classes.spinner}>
              <Spinner
                isCentered
                diameter={24}
                data-hook={PaymentSummaryDataHook.Spinner}
              />
            </div>
          ) : null}
          <div className={classes.details}>
            {priceText ? (
              <div>
                <Text
                  type={TextType.Secondary}
                  data-hook={PaymentSummaryDataHook.Custom_Price}
                >
                  {priceText}
                </Text>
              </div>
            ) : (
              <>
                {priceBreakDownItems.map((item, index) => (
                  <PriceItem
                    key={index}
                    type={TextType.Secondary}
                    labels={item.labels}
                    price={item.price}
                    ariaPrice={item.ariaPrice}
                    dataHook={item.dataHook}
                  />
                ))}
                {priceBreakDownItems.length ? (
                  <Divider className={classes.divider} />
                ) : null}
                {totalPriceItems.map((totalItem, index) => (
                  <PriceItem
                    key={index}
                    type={TextType.Primary}
                    labels={totalItem.labels}
                    price={totalItem.price}
                    ariaPrice={totalItem.ariaPrice}
                    dataHook={totalItem.dataHook}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      ) : (
        <PaymentDetails paymentOption={selectedPaymentOption} />
      )}
    </div>
  ) : null;
};

const getPriceBreakDownItems = ({
  numberOfParticipants,
  showPricePerParticipant,
  couponDiscount,
  price,
  currency,
  locale,
  t,
}: {
  numberOfParticipants: number;
  showPricePerParticipant: boolean;
  couponDiscount: number;
  price: number;
  currency: string;
  locale: string;
  t: TFunction;
}) => {
  return [
    ...(showPricePerParticipant || couponDiscount
      ? [
          {
            labels: [
              t('app.payment.summary.subtotal.text'),
              ...(showPricePerParticipant
                ? [
                    t('app.payment.summary.number-of-participants.text', {
                      numberOfParticipants,
                      price: PriceUtils.getFormattedCurrency({
                        price,
                        currency,
                        locale,
                      }),
                    }),
                  ]
                : []),
            ],
            price: PriceUtils.getFormattedCurrency({
              price,
              currency,
              locale,
              quantity: numberOfParticipants,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price,
              currency,
              locale,
              quantity: numberOfParticipants,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Subtotal,
          },
        ]
      : []),
    ...(couponDiscount
      ? [
          {
            labels: [t('app.payment.summary.promo-code.text')],
            price: PriceUtils.getFormattedCurrency({
              price: -couponDiscount,
              currency,
              locale,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price: -couponDiscount,
              currency,
              locale,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Coupon_Discount,
          },
        ]
      : []),
  ];
};

const getTotalPriceItems = ({
  price,
  totalPrice,
  deposit,
  currency,
  locale,
  t,
}: {
  price: number;
  totalPrice: number;
  deposit: number;
  currency: string;
  locale: string;
  t: TFunction;
}) => {
  const payLater = deposit ? totalPrice - deposit : 0;
  return [
    ...(price
      ? [
          {
            labels: [t('app.payment.summary.total.text')],
            price: PriceUtils.getFormattedCurrency({
              price: totalPrice,
              currency,
              locale,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price: totalPrice,
              currency,
              locale,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Total_Price,
          },
        ]
      : []),
    ...(deposit
      ? [
          {
            labels: [t('app.payment.summary.pay-now.text')],
            price: PriceUtils.getFormattedCurrency({
              price: deposit,
              currency,
              locale,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price: deposit,
              currency,
              locale,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Deposit,
          },
        ]
      : []),
    ...(payLater
      ? [
          {
            labels: [t('app.payment.summary.pay-later.text')],
            price: PriceUtils.getFormattedCurrency({
              price: payLater,
              currency,
              locale,
            }),
            ariaPrice: PriceUtils.getFormattedCurrency({
              price: payLater,
              currency,
              locale,
              currencyDisplay: 'name',
            }),
            dataHook: PaymentSummaryDataHook.Pay_Later,
          },
        ]
      : []),
  ];
};
