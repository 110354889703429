export enum PaymentSummaryDataHook {
  Payment_Summary = 'payment-summary',
  Title = 'payment-summary-title',
  Custom_Price = 'payment-custom-price',
  Total_Price = 'payment-total-price',
  Subtotal = 'payment-subtotal',
  Deposit = 'payment-deposit',
  Coupon_Discount = 'payment-coupon-discount',
  Pay_Later = 'payment-pay-later',
  Summary_Item = 'payment-summary-item',
  Summary_Item_Label = 'payment-summary-label',
  Summary_Item_Description = 'payment-summary-description',
  Spinner = 'payment-summary-spinner',
}
