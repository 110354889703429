import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  Toast as ToastComponent,
  TOAST_PLACEMENT,
  TOAST_SKIN,
} from 'wix-ui-tpa/Toast';
import { ToastErrorDataHooks } from './dataHooks';
import { st, classes } from './Toast.st.css';
import { useFormActions } from '../../Hooks/useFormActions';
import { GenericErrorType } from '../../../../types/errors';

export interface ToastErrorProps {
  toastError: GenericErrorType;
  numberOfParticipants: number;
}

const Toast: React.FC<ToastErrorProps> = ({
  toastError,
  numberOfParticipants,
}) => {
  const { t } = useTranslation();
  const { onToastClose } = useFormActions();
  const { isMobile } = useEnvironment();
  const getToastErrorText = () => {
    switch (toastError) {
      case GenericErrorType.GENERIC_PRICING_PLAN_ERROR:
        return t('app.server-errors.pricing-plan');
      case GenericErrorType.GENERIC_MEMBER_DETAILS_ERROR:
        return t('app.server-errors.users-details');
      case GenericErrorType.SESSION_CAPACITY_EXCEEDED:
        return t('app.server-errors.not-enough-spots-left');
      case GenericErrorType.EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS:
        return t('app.server-errors.exceeded-capacity', {
          numberOfParticipants, // todo: this is the selected number of participants and not the maximum allowed - we need to fix that
        });
      case GenericErrorType.GENERIC_BOOK_ERROR:
      default:
        return t('app.server-errors.default');
    }
  };

  const toastPlacement = isMobile
    ? TOAST_PLACEMENT.bottomFullWidth
    : TOAST_PLACEMENT.inline;

  return (
    <ToastComponent
      onClose={() => onToastClose()}
      className={st(classes.root, { isMobile })}
      skin={TOAST_SKIN.error}
      shouldShowCloseButton
      shouldAnimate
      placement={toastPlacement}
      data-hook={ToastErrorDataHooks.TOAST_ERRORS}
    >
      {getToastErrorText()}
    </ToastComponent>
  );
};

export default Toast;
